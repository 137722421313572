import React from 'react'
import Vegan from '../../components/Vegan/Vegan';
import Bites from '../../components/bites/Bites';
import Gourmet from '../../components/gourmet/Gourmet';
import "./brandInfo.scss";


const BrandInfo = () => {
  return (
    <div className='pro_wrp' >
      <Gourmet/>
      <Vegan />
      <Bites/>
    </div>
  );
}

export default BrandInfo
